import React, { useRef } from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider,
} from "@paypal/react-paypal-js"
import LockerImage from './images/locker.png'
import useSWR from 'swr'
import { createOrder, onApprove } from './actions'
import SubmitPayment from "./SubmitPayment";
import cn from "classnames";
import style from './PayPal.module.scss'

const PayPal = (props) => {
  const { orderId, participantId, fetcher } = props
  const { data, error, isLoading } = useSWR('/api/orders/paypal_token.json',
      () => fetcher('/api/orders/paypal_token.json', {order_id: orderId}),
      { revalidateOnFocus: false, revalidateOnReconnect: false });

  const clientId = process.env.PAY_PAL_CLIENT
  const cardHolderName = useRef(null);
  const country = useRef(null);
  const zip = useRef(null);

  const availableCountries = ['AT', 'AU', 'BE', 'BG', 'BR', 'CA', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HK', 'HU', 'IE', 'IN', 'IT', 'JP', 'LT', 'LU', 'LV', 'MT', 'MY', 'NL', 'NO', 'NZ', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'US']

  if (error) return <div>Token load error, please refresh the page or try later</div>
  if (isLoading) return <div>Loading...</div>

  return (
    <>
      <div id="paypal-button-container" className="paypal-button-container"></div>

      <PayPalScriptProvider
        options={{
          clientId: clientId,
          components: "buttons,hosted-fields",
          dataClientToken: data.client_token,
          intent: "capture",
        }}
      >
        <PayPalButtons
          style={{ label: 'buynow' }}
          createOrder={() => createOrder(orderId)}
          onApprove={() => onApprove(orderId, participantId)}
        />

        {
          !process.env.STRIPE_PUBLIC_KEY && (
            <>
              <div className={cn(style.separator, 'text-secondary mt-3 mb-3')} >or</div>
              <PayPalHostedFieldsProvider
                createOrder={() => createOrder(orderId)}
              >
                <div className="row">
                  <div className="col-12">
                    <PayPalHostedField
                      id="card-number"
                      className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                      hostedFieldType="number"
                      options={{
                        selector: "#card-number",
                        placeholder: "1234 1234 1234 1234",
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <PayPalHostedField
                      id="expiration-date"
                      className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                      hostedFieldType="expirationDate"
                      options={{
                        selector: "#expiration-date",
                        placeholder: "MM/YY",
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <PayPalHostedField
                      id="cvv"
                      className={cn(style.control, 'form-control rounded-3 string optional mb-3')}
                      hostedFieldType="cvv"
                      options={{
                        selector: "#cvv",
                        placeholder: "CVV"
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <input type="text" id="card-holder-name" className={cn(style.control, 'form-control rounded-3 string optional mb-3')} autoComplete="off" placeholder="Name on card" ref={cardHolderName} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <select id="card-billing-address-country" className="form-select rounded-3 string optional mb-3" placeholder="Country" defaultValue="US" ref={country}>
                      {availableCountries.map(country =>
                        <option key={country} value={country}>{country}</option>
                      )}
                    </select>
                  </div>
                  <div className="col-6">
                    <input type="text" id="card-billing-address-zip" className={cn(style.control, 'form-control rounded-3 string optional mb-3')} autoComplete="off" placeholder="Zip code" ref={zip} />
                  </div>
                </div>
                <SubmitPayment cardHolderName={cardHolderName} zip={zip} country={country} {...props} />
              </PayPalHostedFieldsProvider>
              <div className='d-flex flex-row justify-content-center align-items-center'>
                <img src={LockerImage} width='15px' height='20px'></img>
                <div className='to-win mt-1 ms-2'>Secure payment</div>
              </div>
            </>
          )
        }
      </PayPalScriptProvider >
    </>
  );
}

export default PayPal
