import React, {useState} from 'react';
import cn from "classnames";
import style from './SavedCards.module.scss'
import {payWithPaymentMethod} from "../../PayPal/actions";

export default function StripeSavedCards(props) {
    const { orderId, participantId, stripePaymentMethods, currency, cents } = props
    const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cents/100);
    const [selectedCardId, setSelectedCardId] = useState(stripePaymentMethods[0]?.id || null);
    const [isLoading, setIsLoading] = useState(false);


    const pay = () => {
      setIsLoading(true)
      payWithPaymentMethod(orderId, selectedCardId, participantId).finally(() => setIsLoading(false))
    }

    if (stripePaymentMethods.length === 0) {
        return null
    }

    return(
        <div>
            <div className={style.cardsContainer}>
                {stripePaymentMethods.map((card, index) => (
                    <div className={cn(style.bankCard, 'd-flex align-items-center justify-content-between')}
                         onClick={() => setSelectedCardId(card.id)}
                         key={index}
                    >
                        <div style={{ flexGrow: 1 }}>
                            <span className="last-vote-user-name">•••• •••• •••• {card.last4}</span>
                            <p className="last-vote-baby-name">Exp: {card.exp_month}/{card.exp_year}</p>
                        </div>
                        <input
                            type="radio"
                            name="selectedCard"
                            checked={selectedCardId === card.id}
                            onChange={() => setSelectedCardId(card.id)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                ))}
            </div>
            {
                selectedCardId && (
                    <button
                        onClick={pay}
                        disabled={!selectedCardId || isLoading}
                        className="btn btn-primary w-100 h-40i br-8i pt-12i button-text mt-8"
                        style={{fontSize: '16px'}}
                    >
                      {isLoading && <i className="fa fa-spinner fa-spin"> </i>}
                      Pay {formattedPrice}
                    </button>
                )
            }
            <div className={style.dividerWithText}>
                <span className={style.dividerText}>or use another payment method</span>
            </div>
        </div>
    )
}