import React, { useEffect } from 'react';
import useSWR from 'swr'
import axios from 'axios'
import PayPalImage from './images/paypal.svg'
import PayPal from '../PayPal'
import StripeCheckout from "../Stripe";
import cn from "classnames";
import style from './Order.module.scss'
import StripeSavedCards from "../Stripe/SavedCards";

const fetcher = (url, params) => axios.get(url, {params}).then(res => res.data)

const Order = ({ orderId, stripePaymentMethods }) => {
  const { data, error, isLoading } = useSWR(`/api/orders/${orderId}.json`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const [paymentMethod, setPaymentMethod] = React.useState('card')

  useEffect(() => {
    if (data?.pay_before_ms_left && data.pay_before_ms_left < 60 * 60 * 1000) {
      const timer = setTimeout(() => window.location.replace('/'), data.pay_before_ms_left)
      return () => clearTimeout(timer)
    }
  }, [])

  if (error) return <div>Load error, please refresh the page or try later</div>
  if (isLoading) return <div>Loading...</div>

  if (process.env.STRIPE_PUBLIC_KEY) {
    return (
      <div className='position-relative mb-4'>
        <div className='mt-4'>
          <h6 className='contest-home-title'>Select payment method</h6>
          <div className="mt-2" id="accordionExample">
            <div className="accordion-header" id="headingOne" onClick={() => setPaymentMethod('card')}>
              <div className="form-check" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={paymentMethod === 'card'} aria-controls="collapseOne">
                <input className={cn("form-check-input", style.check)} type="radio" id="flexRadioDefault1" checked={paymentMethod === 'card'} />
                <label className={cn("form-check-label", style.label, paymentMethod === 'card' && style.labelChecked)} htmlFor="flexRadioDefault1" >
                  Pay with card
                </label>
              </div>
            </div>
            <div id="collapseOne" className={cn("accordion-collapse collapse pt-3", paymentMethod === 'card' && 'show')} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              { paymentMethod === 'card' &&
                <>
                  <StripeSavedCards stripePaymentMethods={stripePaymentMethods} orderId={orderId} participantId={data.participant_id} cents={data.price_cents} currency={data.price_currency}  />
                  <StripeCheckout stripePaymentMethods={stripePaymentMethods} orderId={orderId} cents={data.price_cents} currency={data.price_currency} participantId={data.participant_id} fetcher={fetcher} />
                </>
              }
            </div>
            <br />
            <div className="accordion-header" id="headingTwo" onClick={() => setPaymentMethod('paypal')}>
              <div className="form-check" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={paymentMethod === 'paypal'} aria-controls="collapseTwo">
                <input className={cn("form-check-input", style.check)} type="radio" id="flexRadioDefault2" checked={paymentMethod === 'paypal'} />
                <label className={cn("form-check-label", style.label)} htmlFor="flexRadioDefault2" >
                  <img src={PayPalImage} height='22px'></img>
                </label>
              </div>
            </div>
            <div id="collapseTwo" className={cn("accordion-collapse collapse pt-3", paymentMethod === 'paypal' && 'show')} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              { paymentMethod === 'paypal' &&
                <PayPal orderId={orderId} cents={data.price_cents} currency={data.price_currency} participantId={data.participant_id} fetcher={fetcher} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='position-relative mb-4'>
      <div className='mt-3'>
        <div className='w-100'>
          <div className='mt-2 mb-1'>
            <a className='form-check fs-5 mt-3'>
              <input className='form-check-input payment-method-radio' type='radio' id='paypal_payment' checked={true} readOnly={true}/>
              <label className='form-check-label' htmlFor='paypal_payment'>
                <div className='last-vote-user-name' id='paypalRadio'>
                  <img src={PayPalImage} height='22px'></img>
                </div>
              </label>
            </a>
          </div>
          <PayPal orderId={orderId} cents={data.price_cents} currency={data.price_currency} participantId={data.participant_id} fetcher={fetcher} />
        </div>
      </div>
    </div>
  )
}

export default Order
