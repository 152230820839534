import React, { useState } from 'react';
import FavoriteStar from '../Search/FavoriteStar'
import style from './ParticipantMenu.module.scss'
import cn from 'classnames'


const FavoritesToggle = ({ participantId, babyName, enabled }) => {
  const [ isFavorite, setFavorite ] = useState(enabled)
  const text = isFavorite ? ' Remove from favorites' : ' Add to favorites'

  return (
    <li className='pt-0'>
      <FavoriteStar isFavorite={isFavorite} setFavorite={setFavorite} id={participantId} babyName={babyName} text={text} wrapperClass='dropdown-item' iconClass={cn(style.menuIcon, 'mb-1')}/>
    </li>
  )
}

export default FavoritesToggle
