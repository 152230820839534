import React, {memo} from "react";
import style from "../Wheel.module.scss";
import Lottie from "lottie-react";
import confityAnimation from "../confity.json";
import WonIcon from "../images/showWonIcon";
import commonStyle from "../../TreasureWheel.module.scss";
import SweetAlert2 from "react-sweetalert2";
import cn from 'classnames';

function CongratulationModal({wonData}) {
    const { balance: {free, paid}, prize } = wonData;

    return(
        <SweetAlert2
            show={!!prize}
            confirmButtonText='Wow, thank you!'
            onResolve={() => Turbolinks.visit()}
            confirmButtonColor='#FF8F51'
        >
            <div className={style.confityContainer}>
                <Lottie animationData={confityAnimation} className={style.confity}/>
                <WonIcon name={prize} />
            </div>
            <h3 className='fs-20i'>Congratulations!</h3>

            <p className={commonStyle.headerTextDescription}>
                It looks like you found the treasure of our Cutie Village!
            </p>
            <p className={cn(commonStyle.balanceDescription, 'mb-0')}>
                You have found
                <span className={commonStyle.boldBalanceDescription}> {prize} votes</span>
            </p>
            <p className={cn(commonStyle.balanceDescription, 'mb-0')}>
                Current bonus balance: {free}
            </p>
            <p className={cn(commonStyle.balanceDescription, 'mb-0')}>
                Current paid balance: {paid}
            </p>
        </SweetAlert2>
    )
}

export default memo(CongratulationModal);