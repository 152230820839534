import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from "@stripe/react-stripe-js";
import StripeForm from "./form";
import useSWR from "swr";

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

function StripeCheckoutForm(props) {
    const { orderId, participantId, fetcher } = props
    const { data, error, isLoading } = useSWR(
        '/api/orders/stripe_client_secret.json',
        () => fetcher('/api/orders/stripe_client_secret.json', {order_id: orderId}),
        { revalidateOnFocus: false, revalidateOnReconnect: false }
    );
    const options = {
        // passing the client secret obtained from the server
        clientSecret: data,
    };

    if (isLoading) {
        return <p>Loading...</p>
    }
    if (error) {
        return <p>Load error, please refresh the page or try later.</p>
    }

    return(
        <Elements stripe={stripePromise} options={options}>
            <StripeForm {...props} />
        </Elements>
    )
}

export default function StripeCheckout(props) {
    // const { stripePaymentMethods } = props
    // const [showForm, setShowForm] = useState(stripePaymentMethods.length === 0);
    //
    //
    // if (!showForm) {
    //     return (
    //         <button
    //             onClick={() => setShowForm(true)}
    //             className="btn btn-primary w-100 h-40i br-8i pt-12i button-text mt-8"
    //         >
    //             More Payment Methods
    //         </button>
    //     )
    // }

    return(
        <StripeCheckoutForm {...props} />
    )
}