import React, {useEffect, useState} from 'react'
import axios from "axios";
import PayPal from "../../PayPal";
import StripeSavedCards from "../../Stripe/SavedCards";
import StripeCheckout from "../../Stripe";
import style from '../BuyButton.module.scss'

const fetcher = (url, params) => axios.get(url, {params}).then(res => res.data)

export default function BuyButtonContent({product, participantId}) {
  const createOrdersPath = `/api/products/${product.id}/create_orders.json`
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.post(createOrdersPath, { participant_id: participantId }).then(({data}) => setData(data))
  }, [])

  if (!data) return <p>Loading...</p>

  return (
    <>
        {
            data.baby && (
                <div
                    className={style.avatar}
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundImage: `url(${data.baby.avatar})`,
                        backgroundSize: 'cover',
                        margin: '10px auto'
                    }}
                ></div>
            )
        }
      <h5 className={style.productTitle}>{product.title}</h5>
      {data.baby && <p className={style.babyName}>{data.baby.name}</p>}
      <PayPal orderId={data.paypal.id} participantId={data.paypal.participant_id} fetcher={fetcher} />

      <div className={style.divider}>
        <span className={style.dividerText}>
          <p className={style.textBody2}>or pay with card</p>
        </span>
      </div>

      <>
        <StripeSavedCards
          stripePaymentMethods={data.payment_methods}
          orderId={data.stripe.id}
          participantId={data.stripe.participant_id}
          cents={data.stripe.price_cents}
          currency={data.stripe.price_currency}
        />
        <StripeCheckout
          stripePaymentMethods={data.payment_methods}
          orderId={data.stripe.id}
          cents={data.stripe.price_cents}
          currency={data.stripe.price_currency}
          participantId={data.stripe.participant_id}
          fetcher={fetcher}
        />
      </>
    </>
  )
}