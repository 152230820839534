import React, { useState } from 'react';
import style from './PayPal.module.scss'
import cn from 'classnames'
import { usePayPalHostedFields } from '@paypal/react-paypal-js'
import {showError, onApprove, failedRedirect} from './actions'

const SubmitPayment = ({ cents, currency, cardHolderName, zip, country, orderId, participantId }) => {
  const amount = cents / 100
  const [paying, setPaying] = useState(false)
  const hostedField = usePayPalHostedFields()
  const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount)

  const handleClick = () => {
    setPaying(true)
    hostedField.cardFields
      .submit({
        cardholderName: cardHolderName?.current?.value,
        billingAddress: {
          postalCode: zip?.current?.value,
          countryCodeAlpha2: country?.current?.value
        },
        contingencies: ['SCA_WHEN_REQUIRED']
      }).then(function (payload) {
        if (payload.liabilityShift && payload.liabilityShift !== 'POSSIBLE') {
          return showError()
        }
        onApprove(orderId, participantId)
      }).catch(() => failedRedirect(orderId))
  }

  return (
    <button className={style.submit} onClick={handleClick} disabled={paying} >
      <div className={cn(style.spinner, { 'd-none': !paying })}></div>
      <span className={cn({ 'd-none': paying })}>Pay {formattedPrice}</span>
    </button>
  )
}

export default SubmitPayment