import React from 'react';
import mainStyle from './SpecialOffer.module.scss'
import pawidolsStyle from './SpecialOfferPawidols.module.scss'
import mainIcon from './images/Icon.svg'
import pawidolsIcon from './images/pawidolsIcon.svg'
import Countdown from "react-countdown";

export default function SpecialOffer({description, endAt, siteName}) {
    const style = siteName === 'pawidols' ? pawidolsStyle : mainStyle;
    const Icon = siteName === 'pawidols' ? pawidolsIcon : mainIcon;

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return (
                <p className={style.timeText}>
                    Special offer finished
                </p>
            )
        } else {
            return (
                <div className={style.tickContainer}>
                    <div className={style.timeContainer}>
                        <p className={style.timeText}>
                            {minutes}
                        </p>
                        <span className={style.tickText}>Minutes</span>
                    </div>
                    <span className={style.title}>:</span>
                    <div className={style.timeContainer}>
                        <p className={style.timeText}>
                            {seconds}
                        </p>
                        <span className={style.tickText}>Seconds</span>
                    </div>
                </div>
            );
        }
    };

    return(
        <div className={style.container}>
            <div className={style.contentContainer}>
                <img src={Icon} className={style.image}></img>
                <div className={style.textContainer}>
                    <p className={style.title}>Special offer for you!</p>
                    <p className={style.description}>{description}</p>
                </div>
            </div>
            <Countdown
                date={endAt}
                renderer={renderer}
            />
        </div>
    )
}