import axios from 'axios';

// Function to get the CSRF token from the meta tag
const getCSRFToken = () => {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  return metaTag.getAttribute('content');
};

// Obtain the CSRF token
const csrfToken = getCSRFToken();

// Default configuration for axios
const defaultAxiosConfig = {
  headers: {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json',
  },
};

// Create an axios client with the default configuration
const axiosClient = axios.create(defaultAxiosConfig);

// Intercept and handle unauthorized requests
axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Handle unauthorized requests, e.g., redirect to the login page
        window.location = '/users/sign_in';
      }
      return Promise.reject(error);
    }
);

export default axiosClient;
