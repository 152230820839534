import React, { useCallback, useState } from 'react';
import Result from './Result'
import style from './Row.module.scss'

const ShowMore = ({ next }) => {
  const [showMore, setShowMore] = useState(false)
  const handleShowMore = useCallback(() => {
    setShowMore(true)
  }, [])

  if (!next) return (<></>)

  if (next && showMore) return (<Result url={next} />) 

  return (
    <div>
      <a className={style.showMore} onClick={handleShowMore}>Show more</a>
    </div>
  )
}

export default ShowMore
