import React, {useCallback, useMemo, useState} from 'react';
import style from './Wheel.module.scss';
import cn from "classnames";
import {post} from "../../../src/util";
import WheelCoinImage from './images/wheelCoin.png'
import CongratulationModal from "./congratulationModal";

// https://codesandbox.io/p/sandbox/spinning-wheel-game-z84nw?file=%2Fsrc%2Fcomponents%2Fwheel%2Findex.js%3A23%2C16-23%2C28

const colors = [
    "#FFEBCC",
    "#E5CCFF",
    "#FFCCF1",
    "#FFEBCC",
    "#E5CCFF",
    "#FFCCF1",
    "#FFEBCC",
    "#E5CCFF",
    "#FFCCF1",
    "#FFEBCC",
    "#E5CCFF",
    "#FFCCF1",
]

export default function Wheel({listOfPrizes}) {
    const { innerWidth: width } = window;
    const [selectedItem, setSelectedItem] = useState(0);
    const [inProgress, setInProgress] = useState(false);
    const [wonData, setWonData] = useState({prize: null, balance: {}});

    const wheelSize = useMemo(() => (
        `${Math.min(width * 0.7, 375)}px`
    ), [])

    const wheelVars = {
        "--nb-item": listOfPrizes.length,
        "--selected-item": selectedItem,
        "--wheel-size": wheelSize,
    };

    const openModal = useCallback(({prize, balance}) => {
        setTimeout(() => {
            setWonData({prize, balance})
        }, 2000)
    }, [])

    const onSpinWheel = () => {
        if (selectedItem) {
            return null
        }

        setInProgress(true)
        post('/api/users/find_treasure').then(({ data: { prize, balance } }) => {
            const resultIndex = listOfPrizes.findIndex((l) => l === prize)
            if (resultIndex !== -1) {
                setSelectedItem(resultIndex + listOfPrizes.length * 20)
            }
            setTimeout(() => {
                openModal({prize, balance})
                setInProgress(false)
            }, 7000)

        }).catch(({response: { data: { message } }}) => console.log(message))
    }

    return(
        <div className={style.wheelContainer} style={wheelVars} onClick={onSpinWheel}>
            <div className={cn(style.wheel, style.wheelSpinning)}>
                {listOfPrizes.map((prize, index) => (
                    <div
                        className={style.wheelItem}
                        key={index}
                        style={{ "--item-nb": index, '--item-clr': colors[index] }}
                    >
                        <span className={style.prizeText}>{prize}</span>
                    </div>
                    )
                )}
            </div>
            <img src={WheelCoinImage} alt='sping wheel' className={cn(style.centerImage, style.pulseAnimation)}></img>
            <CongratulationModal wonData={wonData} />
        </div>
    )
}