import React from 'react';
import style from './TreasureWheel.module.scss';
import cn from 'classnames';
import Wheel from "./Wheel";

export default function TreasureWheel({listOfPrizes, isPremium}) {

    return (
        <div className={cn(style.container, 'container')}>
            <div className={style.headerContainer}>
                <h3 className='fs-20i'>
                    {isPremium ? 'PREMIUM Village treasure' : 'Daily village spin'}
                </h3>
                <p className={style.headerTextDescription}>
                    {
                        isPremium
                          ? 'Win up to 1000 votes with your premium village treasure attempt! Good Luck!'
                            : 'Tap the gem in the middle of the wheel to start the Spinner. ' +
                            'We give away free spins to people daily, so make sure to visit every day!'
                    }
                </p>
            </div>
            <Wheel listOfPrizes={listOfPrizes} />
        </div>
    )
}