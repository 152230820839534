import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {failedRedirect, successRedirectPath} from "../../PayPal/actions";

export default function StripeForm({orderId, participantId, cents, currency}) {
    const stripe = useStripe();
    const elements = useElements();
    const returnUrl = 'https://' + process.env.SITE_HOST + successRedirectPath(orderId, participantId)
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cents/100);

    const handleSubmit = async (event) => {
        setIsLoading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        setIsLoading(false)

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            failedRedirect(orderId)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return(
        <form onSubmit={handleSubmit}>
            <PaymentElement onReady={() => setIsReady(true)} />
            <button
                disabled={!stripe || !isReady || isLoading}
                className="btn btn-primary w-100 h-40i br-8i pt-12i button-text mt-8"
                style={{fontSize: '16px'}}
            >
                {isLoading && <i className="fa fa-spinner fa-spin"> </i>}
                Pay {formattedPrice}
            </button>
        </form>
    )
}