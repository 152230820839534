import React, {  } from 'react';
import Row from './Row'
import axios from 'axios'
import useSWR from 'swr'
import ShowMore from './ShowMore';

const Result = ({ url, favoritesMode }) => {
  const fetcher = url => axios.get(url).then(res => res.data)
  const { data, error, isLoading } = useSWR(url, fetcher)
  const { data: participants, links } = data || {}
  

  if (error) return <div>Load error, please refresh the page or try later</div>
  if (isLoading) return <div>Loading...</div> 
  if (!favoritesMode && (!participants || participants.length === 0)) return <div> No results</div>

  return (
    <>
      <div>
      {
        participants.map((participant, index) => (
          <Row key={index} id={participant.id} favoritesMode={favoritesMode} {...participant.attributes} />
        ))
      }
      </div>
      <ShowMore {...links} />      
    </>
  )
}

export default Result
