import React from 'react';
import FavoritesToggle from './FavoritesToggle'
import cn from 'classnames'
import style from './ParticipantMenu.module.scss'

import dotsImage from './images/dots.svg'
import shareImage from './images/share.svg'
import settingsImage from './images/settings.svg'
import redFlagImage from './images/red-flag.svg'

const ParticipantMenu = ({ currentUserId, participantId, isFavorite, isMyParticipant, babyId, babyName, editUrl }) => {
  const editBabyPath = editUrl

  const openModal = () => {
    $('#shareModal').modal('show')
  }

  const reportModal = () => {
    $(`#report-baby-modal-${babyId}`).modal('show')
  }

  return (
    <nav className='navbar navbar-dots navbar-expand-lg ms-auto'>
      <div className='justify-content-lg-end dropstart' id='navbarNavAltMarkup'>
        <ul className='align-items-center no-bullets'>
          <li className='dropdown pt-0'>
            <a className='nav-link text-primary' id='navbarScrollingDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
              <img className='ms-auto' src={dotsImage}/>
            </a>
            <ul className={cn(style.menu, 'dropdown-menu')} aria-labelledby='navbarScrollingDropdown'>
              <li className='pt-0'>
                <div className='dropdown-item' onClick={openModal}>
                  <img className={cn(style.menuIcon, 'mb-1')} src={shareImage} /> Share
                </div>
              </li>
              { currentUserId && <FavoritesToggle participantId={participantId} enabled={isFavorite} babyName={babyName} /> }
              { currentUserId && isMyParticipant && 
                <li className='pt-0'>
                  <a href={editBabyPath} className='dropdown-item' >
                    <img src={settingsImage} className={cn(style.menuIcon, 'mb-1')} /> Edit
                  </a>
                </li>
              }
              <li className='pt-0'>
                <div className='dropdown-item' onClick={reportModal}>
                  <img className={cn(style.menuIcon, 'mb-1')} src={redFlagImage} /> Report
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default ParticipantMenu
