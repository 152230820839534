import React, { useCallback } from 'react'
import cn from 'classnames'

import emptyStar from './images/empty-star.svg'
import filledStar from './images/filled-star.svg'
import { post, del } from '../../src/util'

const FavoriteStar = ({ isFavorite, setFavorite, id, babyName, text, wrapperClass, iconClass}) => {
  const toggleFavorite = useCallback((event) => {
    const url = `/api/participants/${id}/favorites.json`
    const reqFunc = (isFavorite ? del : post)
    reqFunc(url)
      .then(() => {
        Application.Classes.Toastr.success(`${babyName} was ${isFavorite ? 'deleted from' : 'added to'} your favorite list`)
        setFavorite(!isFavorite)
        event.stopPropagation()
        return(false)
      })
      .catch(() => {})
  }, [isFavorite])

  return (
    <div onClick={toggleFavorite} className={wrapperClass} >
      <img className={cn(iconClass, 'primary-main-color-svg')} src={isFavorite ? filledStar : emptyStar} />
      {text}
    </div>
  )
}

export default FavoriteStar
