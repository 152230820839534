import React, { useCallback, useEffect, useState } from 'react';
import SearchImage from './images/search.svg'
import style from './Search.module.scss'
import Result from './Result';

const Search = ({ contestId }) => {
  const [query, setQuery] = useState('')
  const [debounceQuery, setDebounceQuery] = useState('')
  const url = contestId ? `/api/participants.json?q=${debounceQuery}&contest_id=${contestId}` : `/api/participants.json?q=${debounceQuery}`

  const handleSearch = useCallback(({ target: { value } }) => {
    setQuery(value);
  }, [])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebounceQuery(query);
    }, 250);
    
    return () => {
      clearTimeout(timerId);
    };
  }, [query])

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <img className={style.icon} src={SearchImage}/>
        <input type='text' value={query} onChange={handleSearch} className={style.field} placeholder="Search"/>
      </div>
      <Result url={url} favoritesMode={!debounceQuery} />
    </div>
  )
}

export default Search
